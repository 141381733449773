.gpt3__features-container__feature{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 1rem;

}
.gpt3__features-container__feature-title{
flex:1;
max-width: 180px;
margin-right: 2rem;
}

.gpt3__features-container__feature-title h1{
font-family: Manrope;
font-weight: 800;
font-size: 18px;
line-height: 24px;
letter-spacing: -0.04em;
color:#fff;
}

.gpt3__features-container__feature-title div{
    width: 38px;
    height: 3px;
    background: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
    box-shadow: 0 4px 4px rgba(0,0,0,0.3);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text{
    flex: 2;
    max-width: 390px;
    display: flex;
}

.gpt3__features-container_feature-text p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: var(--color-text);
    /* color:'red' */
}

@media screen and (max-size:550px) {
    .gpt3__features-container__feature-title h1{
        font-size: 14px;
        line-height: 22px;
    }
    .gpt3__features-container__feature-title text{
        font-size: 20px;
        line-height: 20px;
    }
    .gpt3__features-container__feature{
        margin: 1rem 0;
    }
}
